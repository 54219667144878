import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Accordion
const length = $('.list-itens').length;
if(length == 1) { $('.list-itens').addClass('open') }

$('.list-itens').click(function() {
	const has = $(this).hasClass('open');
	$('.list-itens').removeClass('open');
	$(this).addClass('open');
	if( has ) { 
		$(this).removeClass('open');
	}
});


// Add class textarea container
$('.area').closest('p').addClass('textarea-container');

// closest
$('[type="checkbox"]').closest('p').addClass('checkbox-container');
$('[type="submit"]').closest('p').addClass('submit-container');

$('input, textarea').each(function(){ 
  const default_value = $(this).val(); 
  const $this = $(this);        

  $this.focus( function() {    
    if ($this.val() == default_value) $(this).val("");
  });
  $this.blur( function() {
    if ($this.val().length == 0){
      $this.val(default_value); 
    }
  })            
}); 

$('input[type="checkbox"]').click(function() {
	$(this).toggleClass('checked');
});

// Contact form input placeholder and blur
$('form p').each(function(){
	const input = $(this).find('input');
	const placeholder = input.attr('placeholder');
	input
		.attr('onfocus', "this.placeholder = ''")
		.attr('onblur', `this.placeholder = '${placeholder}'`);
});


// Fade in fade out
$('body').fadeIn(800);
let newLocation = null;
$('.menu a, a[type="internal"]').click(function(e) {
	e.preventDefault();
	newLocation = this.href;
	if(newLocation) {
		$('body').fadeOut(800, newpage);
	} else {
		return;
	}
});
function newpage() {
	window.location = newLocation;
}


// Animation
AOS.init();
AOS.refresh();